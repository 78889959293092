:global(#app) {
  .item {
    padding: 12px;
    margin: 12px;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    &:hover {
      background: #f0f0f0;
    }
  }

  .commentBlock {
    padding: 8px;
    border-radius: 8px;
    background-color: #d5d5d5;
    color: #343434;
  }

  .notificationTypeText {
    color: #4b4b4b;
    margin-left: 5px;
    margin-top: 5px;
    flex-grow: 10;
  }

  .title {
    font-size: 14px;
    color: black;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .projectOnly {
    font-size: 14px;
    color: black;
    float: left;
  }

  .showRead {
    font-size: 14px;
    color: black;
    float: right;
  }

  .itemButton {
    background: transparent;
    box-shadow: none;
    float: right;
    height: 20px;
    line-height: 20px;
    margin: 0;
    min-height: auto;
    padding: 0;
    transition: background 0.3s ease;
    width: 20px;

    &:hover {
      background: #e9e9e9;
    }
  }

  .cardColor {
    color: rgb(0, 110, 255)9
  }

  .itemContent {
    display: inline-block;
    font-size: 14px;
    min-height: 36px;
    overflow: hidden;
    padding: 0 4px 0 8px;
    vertical-align: top;
    width: calc(100% - 56px);
    word-break: break-word;
  }

  .itemDeleted {
    display: inline-block;
    line-height: 20px;
    min-height: 20px;
    padding: 0 4px 0 8px;
    vertical-align: top;
    width: calc(100% - 20px);
  }

  .wrapper {
    margin: 0px -12px;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
    }
  }

  .deleteAllButton {
    background: transparent;
    box-shadow: none;
    transition: background 0.3s ease;

    display: block;
    margin: 0 auto;
    padding: 0.5em 1em;
    font-size: 0.875em;

    &:hover {
      background: #e9e9e9;
    }
  }
}
