:global(#app) {
  .controls {
    clear: both;
    margin-top: 6px;
  }

  .field {
    background: #fff;
    border: 1px solid rgba(9, 30, 66, 0.13);
    border-radius: 3px;
    box-sizing: border-box;
    color: #333;
    display: block;
    line-height: 1.4;
    font-size: 14px;
    margin-bottom: 4px;
    overflow: hidden;
    padding: 8px 12px;
    resize: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .mentions {
    background: #fff;
    border: 0;
    box-sizing: border-box;
    color: #333;
    display: block;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 6px;
    height: 150px;
    resize: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
  }

  .mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
  }

  .mentions--singleLine .mentions__input {
    padding: 1px;
    border: 2px inset;
  }

  .mentions--multiLine .mentions__control {
    font-size: 14pt;
  }

  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
  }

  .mentions--multiLine .mentions__input {
    border: 1px solid silver;
    padding: 9px;
    outline: 0;
    line-height: 1.5;
    letter-spacing: 2px;
    font-size: 14pt !important;
  }

  .mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
    position: absolute;
    z-index: 1000;
    margin-top: 10px !important;
  }

  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .mentions__suggestions__item--focused {
    background-color: #cee4e5;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    color: white;
    background-color: #177684;
    border-radius: 12px;
    text-shadow: none;
    text-decoration: none;
    pointer-events: none;
  }

  .suggestionItem {
    display: flex;
    align-items: center;
    padding: 2px 2px;
    width: 150px;

    .userInfo {
      margin-left: 10px;
    }

    .name {
      font-weight: bold;
    }

    .username {
      font-size: 12px;
      color: gray;
    }

    &.focused {
      background-color: #cee4e5;
    }
  }
}
