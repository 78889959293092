:global(#app) {
  .deleteButton {
    bottom: 12px;
    box-shadow: 0 1px 0 #cbcccc;
    position: absolute;
    right: 9px;
  }

  .fieldBox {
    display: inline-block;
    margin: 0 4px 12px;
    width: calc(50% - 8px);
  }

  .fieldWrapper {
    margin: 0 -4px;
  }

  .text {
    color: #444444;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 4px;
    padding-left: 2px;
  }

  .numberGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    gap: 10px;
    max-width: 300px;
  }

  .numberButton {
    width: 100%;
    padding-top: 100%;
    position: relative;
    border: none;
    background: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 5px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .numberButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .numberButtonActive {
    background: #cccccc;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
  }

  .numberButton span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
