:global(#app) {
  .menu {
    &>* {
      display: inline-block;
    }

    &>*+* {
      margin-left: 15px;
    }
  }
}
