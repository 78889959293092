:global(#app) {
  .author {
    color: #17394d;
    display: inline-block;
    font-weight: bold;
    line-height: 20px;
  }

  .content {
    border-bottom: 1px solid #092d4221;
    display: inline-block;
    padding-bottom: 14px;
    vertical-align: top;
    width: calc(100% - 40px);
  }

  .date {
    color: #6b808c;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
  }

  .text {
    line-height: 20px;
  }

  .user {
    display: inline-block;
    padding: 4px 8px 0 0;
    vertical-align: top;
  }

  .wrapper {
    cursor: pointer;
    margin-bottom: 8px;
    min-height: 80px;
    position: relative;

    &:hover {
      .details {
        background: rgba(9, 30, 66, 0.04);
      }

      .target {
        opacity: 1;
      }
    }
  }

}
